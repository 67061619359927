.add-problem-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 5px;
}

.add-problem-container h2 {
  font-family: 'Titillium Web';
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

.add-problem-form {
  font-family: 'Cascadia Code';
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-problem-form label {
  color: black;
  margin-top: 10px;
}

.add-problem-form input[type="text"],
.add-problem-form textarea,
.add-problem-form select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  resize: vertical;
}

.add-problem-form select {
  background-color: white;
}

.tags-container {
  display:flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.tags-container label {
  margin-right: 15px;
  color: black;
}

.submit-button {
  font-family: 'Cascadia Code';
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #333;
}
