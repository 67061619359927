  .input-label {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 7px;
      color: #333;
      margin-top: 5px;
  }

  .input-container {
      width: 30%;
  }

.input-textarea {
    width: 100%;
    height: 140px;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

  .input-textarea:focus {
      outline: none;
      border-color: #000;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }