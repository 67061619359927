  .output-label {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 7px;
    color: #333;
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .output-label2 {
    display: flex;
    flex: 1;
  }

  .exec-time {
    margin-left: 10px; 
    align-content: right;
  }

  .output-container {
    width: 70%;
    margin-right: 25px;
    margin-left: 40px;
  }
  
  .output-textarea {
    width: 100%;
    height: 140px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }

  
  .output-textarea:focus {
    outline: none;
    border-color: #000;
    box-shadow:  0 0 5px rgba(0, 0, 0, 0.5);
  }

  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-top: 5px;
    margin-left: 5px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  